.navbar {
    color: white !important;
    background-color: #242424 !important;
    width: 100%;
}

.navbar a {
    color: white !important;
}

.ant-page-header {
    padding: 10px 18px !important;
}

.ant-page-header-content {
    padding-top: 0px !important;
}

.ant-page-header-heading-title {
    margin: 0px !important;
    color: white !important;
    font-weight: 300 !important;
}